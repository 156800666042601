import React from 'react'
import { BrowserRouter, Route, Routes } from "react-router-dom"
import LoginForm from '../pages/Login/Login';
import SignupForm from '../pages/Signup/Signup';
import { ValidateEmail } from '../pages/ValidateEmail/ValidateEmail';
import Home from '../pages/Home/Home';
import ConversionStep2 from '../pages/Home/Conversion/components/Step2/ConversionStep2';
import Deposit from '../pages/Home/Deposit/Deposit';
import Dashboard from '../pages/Home/Dashboard';
import KYC from '../pages/KYC/KYC';
import Withdraw from '../pages/Withdraw/Withdraw';
import Investment from '../pages/Investment/Investment';
import ChangePassword from '../pages/ChangePassword/ChangePassword';
import Profile from '../pages/Profile/Profile';
import UnstakeFreeShares from '../pages/Investment/UnstakeFreeShares';
import UnStakeLockedShares from '../pages/Investment/UnStakeLockedShares';
import UnStakePrivateShares from '../pages/Investment/UnStakePrivateShares';

const AppRoutes = () => {

    return (

        <BrowserRouter>

            <Routes>
                
                <Route path='/login' element = {<LoginForm />}/>
                <Route path='/validate-email' element = {<ValidateEmail />}/>
                <Route path='/deposit' element = {<Deposit />}/>
                <Route path='/' element = {<Dashboard />}/>
                <Route path='/kyc' element = {<KYC />}/>
                <Route path='/withdraw' element = {<Withdraw  />}/>
                
                <Route path='/investment' element = {<Investment  />}/>
                <Route path='/change-password' element = {<ChangePassword />}/>
                <Route path='/profile' element = {<Profile />}/>
                <Route path='/unstake-free' element = {<UnstakeFreeShares />}/>
                <Route path='/unstake-locked' element = {<UnStakeLockedShares />}/>
                <Route path='/unstake-private' element = {<UnStakePrivateShares />}/>
            </Routes>

        </BrowserRouter>
    )
}

export default AppRoutes;