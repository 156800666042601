import { http } from "../ConnectApi/ConnectApi";

export async function DocumentInputController(data: {
  documentType: string;

})
 
{
  const jwtToken = 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJhY2Nlc3MiOnsiYWNjZXNzUGVybWlzc2lvbnMiOnsiYWRtaW4iOnRydWUsImNvbnZlcnQiOnRydWUsImlkIjoiMWM5ZWJhYjItOGI2ZS00YjZhLWIxNTYtZDYwMjQ5ZDAxNzRhIiwib25DaGFpbiI6dHJ1ZSwicGF5SW4iOnRydWUsInBheU91dCI6dHJ1ZX0sImVtYWlsIjoidGVjaCtwZkBicmxhLmRpZ2l0YWwiLCJpZCI6IjE3YWYwMTM1LTcxYjktNDE2MS04MWM4LWVkMzU2N2IxZjVkMSJ9LCJleHAiOjE3MjkxMjk2OTMsImlhdCI6MTcyODUyNDg5MywiaW5mbyI6eyJhY2NvdW50VHlwZSI6IklORElWSURVQUwiLCJjb3VudHJ5IjoiQlJBIiwiY291bnRyeVN1YkRpdmlzaW9uIjoiU1AiLCJpZCI6ImM0NTAyNGVjLWFlOTQtNGZmNi05MmZjLTU0Yzg0ZmNkMjMyZSIsIndvcmtzcGFjZUlkIjoiMGVhNDIxMjEtYWJmNi00M2IxLWJhNWQtODIyNjQyZjlmNDdmIiwid29ya3NwYWNlTWFpblVzZXJJZCI6ImQyYzViODUwLTRiYWUtNGIwOS1iOWExLTI3N2NkYWJmMmQxYSJ9LCJzdWIiOiJkMjJlOWQ0MS1mZDM3LTQwNTUtYTljMi1kOGVkYWM5N2I1Y2UifQ.h455QooG3I_cyLVYw9s8zF6e05K27-IIQT7nTZzIZY8'; 
  try {
    const response = await http.post('/tftc/kyc/level2', data, {
     
      withCredentials: true
    });

    return response;
  } catch (error: any) {
    console.error('Error during request:', error.response?.data || error.message);
    throw error;
  }
}
