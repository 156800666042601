import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowRight } from '@fortawesome/free-solid-svg-icons';
import LogoGreen from '../../components/LogoGreen';

interface KYCStatusProps {
    status: 'success' | 'pending' | 'failed';
    onClick: () => void;
}

const statusConfig = {
    success: {
        text: 'Pronto!',
        description: 'Seu cadastro foi aprovado com sucesso.',
        iconPath: './check-white.svg',
        elipse1: './elipse-1.svg',
        elipse2: './elipse-2.svg',
        elipse3: './elipse-3.svg',
        iconClass: ''
    },
    pending: {
        text: 'Pendente',
        description: 'Seu cadastro está em análise. Por favor, aguarde.',
        iconPath: './icon-clock.svg',
        elipse1: './elipse-1-pending.svg',
        elipse2: './elipse-2-pending.svg',
        elipse3: './elipse-3-pending.svg',
        iconClass: '' // Remove o spin no ícone para usar o efeito blink nas elipses
    },
    failed: {
        text: 'Falhou',
        description: 'Houve um problema com seu cadastro. Tente novamente.',
        iconPath: './icon-x.svg',
        elipse1: './elipse-1-failed.svg',
        elipse2: './elipse-2-failed.svg',
        elipse3: './elipse-3-failed.svg',
        iconClass: ''
    }
};

export default function KYCStatus({ status, onClick }: KYCStatusProps) {
    const { text, description, iconPath, elipse1, elipse2, elipse3, iconClass } = statusConfig[status];

    return (
        <div className="flex justify-center items-center bg-gray-100">
            <div className="bg-white p-8 rounded-lg shadow-lg text-center w-[430px] relative">
                {/* Logo */}
                <div className="flex justify-center mb-6">
                    <LogoGreen />
                </div>

                {/* Imagem com os círculos sobrepostos */}
                <div className="relative flex justify-center items-center mb-6 h-[150px]">
                    <img src={elipse3} alt="Status Background" className={`absolute w-[150px] h-[150px] ${status === 'pending' ? 'animate-blink' : ''}`} />
                    <img src={elipse2} alt="Status Background" className={`absolute w-[120px] h-[120px] ${status === 'pending' ? 'animate-blink' : ''}`} />
                    <img src={elipse1} alt="Status Background" className={`absolute w-[90px] h-[90px] ${status === 'pending' ? 'animate-blink' : ''}`} />
                    <img src={iconPath} alt="Status Icon" className={`absolute w-[40px] h-[40px] ${iconClass}`} />
                </div>

                {/* Título */}
                <h2 className="text-2xl font-bold text-gray-800 mb-4">{text}</h2>

                {/* Texto de descrição */}
                <p className="text-gray-500 mb-6">{description}</p>

                {/* Botão para tela principal */}
                {status !== 'pending' && (
                    <button onClick={onClick} className="bg-[#00E07B] text-white px-6 py-2 rounded-lg flex items-center justify-center w-full hover:bg-green-600 transition-all">
                        <div className="flex items-center">
                            <span>{status === 'failed' ? 'Realizar verificação novamente' : 'Prosseguir para a próxima etapa'}</span>
                            <FontAwesomeIcon icon={faArrowRight} className="ml-2 mt-0.5" />
                        </div>
                    </button>
                )}
            </div>
        </div>
    );
}
