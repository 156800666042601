import React, { useState, FormEvent, useEffect } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEnvelope, faKey, faFile, faTimes, faPerson, faPassport, faIndent, faIdCard } from '@fortawesome/free-solid-svg-icons';
import { Button } from 'flowbite-react';
import { useNavigate } from "react-router-dom";
import LogoGreen from '../../components/LogoGreen';
import { ENDPOINT } from '../../variaveis/variaveis';
import { CpfFormat } from '../../components/services/CpfFormat';
import { cpfValidate } from '../../components/services/CpfValidate';
import InputModel, { Field } from '../../components/Input/InputModel/InputModel';
import { usePasswordValidation } from '../../components/hooks/Password/usePasswordValidation';
import { useForm, Controller } from 'react-hook-form';
import dayjs from 'dayjs';
import 'dayjs/locale/pt';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import KYCStatus from './KYCStatus';
import { AccountInfoController } from '../../controller/AccountInfoController/AccountInfoController';


const BasicKYC: React.FC = () => {
    const { control, handleSubmit, formState: { errors } } = useForm();
    const [fullName, setFullName] = useState('');
    const [taxId, setTaxId] = useState('');
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState('');
    const [taxIdError, setTaxIdError] = useState('');
    const [currentStep, setCurrentStep] = useState(1);
    const [status, setStatus] = useState<'initial' | 'PROCESSING' | 'APPROVED' | 'REJECTED'>('initial');
    const navigate = useNavigate();
   
   
   

    const submitSignUp = async () => {
        setLoading(true);
        setError('');

        const requestData = {
            fullName: fullName,
            cpf: taxId,
           
        };

        try {
            const response = await fetch(`${ENDPOINT}/kyc/level1`, {
                method: 'POST',
                credentials: 'include',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify(requestData),
            });

            if (response.ok) {
                setStatus('PROCESSING'); // Define o status para 'PROCESSING' ao receber 201
                startPolling();
                return;
            }

            const data = await response.json();

            if (!response.ok) {
                setError(data.error);
                throw new Error(data.error || 'Error to submit signup');
            }
        } catch (error:any) {
            if (error.status===401) {
                navigate('/login')
            }
            if (error instanceof Error) {
                setError(error.message);
            } else {
                setError('Ocorreu um erro desconhecido');
            }
        } finally {
            setLoading(false);
        }
    };

    const handleSignup = async (e: FormEvent) => {
        e.preventDefault();
        switch (currentStep) {
            case 1:
                await submitSignUp();
                break;
      
            default:
                console.log('Passo inválido');
        }
    };

    const handleTaxIdChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setTaxId(e.target.value);
      
            if (!cpfValidate(e.target.value)) {
                setTaxIdError('Please enter a valid TaxId');
            } else {
                setTaxIdError('');
            }
        
    };

    function Step1Valid() {
      
            return  fullName && taxId ;
        
    }

    function isDisabled() {
        return !Step1Valid() || loading;
    }

    function KYCClick() {
        window.location.reload();
    }

        // Polling para verificar o status do KYC após 201
        const startPolling = () => {
            const intervalId = setInterval(async () => {
                const response = await AccountInfoController();
                const currentStatus = response?.data?.status;
                if (currentStatus !== 'PROCESSING') {
                    setStatus(currentStatus);
                    clearInterval(intervalId);
                }
            }, 5000);
        };
    
    
    if (status === 'PROCESSING') {
        return <KYCStatus status="pending" onClick={KYCClick} />;
    }

    if (status === 'APPROVED') {
        return <KYCStatus status="success" onClick={KYCClick} />;
    }

    if (status === 'REJECTED') {
        return <KYCStatus status="failed" onClick={KYCClick}/>;
    }

    return (
        <div className='flex justify-center items-center h-screen'>
            <form onSubmit={handleSignup} className="w-[382px] max-h-[600px] bg-white rounded-lg shadow-lg p-6">
                <LogoGreen />
                <span className="font-inter font-bold text-2xl tracking-tight text-gray-800 block mb-6 mt-10">Verificação básica</span>
                {currentStep === 1 && (
                    <>

                        <div className="flex items-center w-full h-[52px] gap-4 mb-4 bg-gray-100">
                        <FontAwesomeIcon icon={faPerson} className="h-4 w-4 ml-4 text-gray-500" />
                          
                            <input type="text" className="form-input w-full py-2 rounded-md bg-gray-100 border-transparent ring-0 focus:border-transparent focus:ring-0 ml-5" placeholder="Nome completo" onChange={(e) => setFullName(e.target.value)} />
                        </div> 
                       
                        <div className="flex items-center w-full h-[52px] gap-4 mb-4 bg-gray-100">
                            <FontAwesomeIcon icon={faIdCard} className="h-4 w-4 ml-4 text-gray-500" />
                            <input type="text" className="form-input w-full py-2 rounded-md bg-gray-100 border-transparent ring-0 focus:border-transparent focus:ring-0" placeholder="CPF" value={taxId} onChange={handleTaxIdChange} onInput={ CpfFormat} />
                        </div>
                       



                    </>
                )}
             
                <Button type="submit" disabled={isDisabled()} className={`w-full flex justify-center items-center px-5 py-1 bg-[#0A1F27] rounded-lg text-white mt-10 ${isDisabled() ? 'opacity-50 cursor-not-allowed' : ''}`}>
                    {loading ? 'Loading...' : 'Próximo'}
                </Button>
                {error && <div className="text-red-500 mt-2">{error}</div>}
                {taxIdError && <div className="text-red-500 mt-2">{taxIdError}</div>}
            </form>
        </div>
    );
}



export default BasicKYC;
