import { useEffect, useState } from "react";
import DepositsContainer from "../Conversion/components/Container/DepositsContainer";
import QRCode from "qrcode.react";
import { useCurrency } from "../../../context/Currency/CurrencyContext";
import ContainerService from "../Conversion/components/Container/ContainerService";
import { useNavigate } from "react-router-dom";
import LogoGreen from "../../../components/LogoGreen";
import Completed from "../Conversion/components/Completed/Completed";
import { getPixToUsdHistoryController } from "../../../controller/GetPixToUsdHistory/GetPixToUsdHistory";
import CopyToClipBoard from "../../../components/Input/CopyToClipBoard/CopyToClipboard";
import { BuyBRLAController } from "../../../controller/BuyBRLAController/BuyBRLAController";
import { toast, ToastContainer } from "react-toastify";

async function initiateDeposit(amount: any) {
    const data = { amount };
    try {
        const result = await BuyBRLAController(data);
        return result;
    } catch (error: any) {
        console.error('Error during request:', error.response?.data || error.message);
        toast.error('Error during request: ' + (error.response?.data || error.message));
        throw error;
    }
}

const Deposit: React.FC = () => {
    const { state } = useCurrency();
    const [tx, setTx] = useState('');
    const [isCompleted, setIsCompleted] = useState(false);
    const [depositValue, setDepositValue] = useState('');
    const [showQRCode, setShowQRCode] = useState(false);
    const [QRCodeData, setQRCodeData] = useState('');
    const [loading, setLoading] = useState(false);
    const [errorMessage, setErrorMessage] = useState(''); // Controle da mensagem de erro
    const navigate = useNavigate();

    

    const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const value = e.target.value;
        setDepositValue(value);

        // Validação do valor mínimo no onChange
        const depositAmount = parseFloat(value);
        if (isNaN(depositAmount) || depositAmount < 100) {
            setErrorMessage("O valor mínimo para depósito é R$100.");
        } else {
            setErrorMessage(""); // Limpa o erro se o valor for válido
        }
    };

    const handleDeposit = async () => {
        if (errorMessage || !depositValue) return;

        try {
            const depositAmount = parseFloat((parseFloat(depositValue) * 100).toFixed(0));
            setLoading(true);
            const result = await initiateDeposit(depositAmount);

            if (result.status === 201) {
                setQRCodeData(result.data.qrCode)
                setShowQRCode(true);
            }
        } catch (error: any) {
            if (error.status === 401) {
                navigate('/');
            }
            console.error("Erro ao iniciar o depósito.");
        } finally {
            setLoading(false);
        }
    };

    const qrCode = (
        <QRCode
            value={QRCodeData}
            size={150}
            renderAs="svg"
            imageSettings={{
                src: "/favicon.ico",
                height: 24,
                width: 24,
                excavate: true,
            }}
        />
    );

   
    return (
        <ContainerService path="/" linkText="Dashboard">
          
                <>
                    <div className="flex justify-center items-center mb-8 mr-10">
                        <LogoGreen />
                    </div>

                    <DepositsContainer
                        qrCode={showQRCode ? (
                            <div className="flex flex-col items-center">
                                {qrCode}
                                <CopyToClipBoard copyItem={QRCodeData} />
                            </div>
                        ) : (
                            <div className="flex flex-col items-center">
                                <input
                                    type="number"
                                    placeholder="Digite o valor de depósito"
                                    className="mb-4 p-2 border rounded"
                                    value={depositValue}
                                    onChange={handleInputChange}
                                />
                                {errorMessage && (
                                    <p className="text-red-500 text-sm mb-2">{errorMessage}</p> // Mensagem de erro em vermelho
                                )}
                                <button
                                    className="bg-[#00dc84] text-white px-4 py-2 rounded"
                                    onClick={handleDeposit}
                                    disabled={loading || !!errorMessage}
                                >
                                    {loading ? "Processando..." : "Confirmar depósito"}
                                </button>
                            </div>
                        )}
                        heading={showQRCode ? "Escaneie o código PIX" : "Digite o valor que deseja depositar"}
                        subHeading="Clique em voltar quando realizar o pagamento."
                        description="Cheque nossos termos e serviços. Além disso, preste atenção em nossas políticas de Compliance, AML e política contra financiamento e terrorismo."
                        copyItem={QRCodeData}
                        activeIndex={2}
                    />
                </>
            
            <ToastContainer />
        </ContainerService>
    );
};

export default Deposit;
