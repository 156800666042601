import React, { useState, FormEvent } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEnvelope, faKey, faGift } from '@fortawesome/free-solid-svg-icons';
import { Button } from 'flowbite-react';
import { useNavigate } from "react-router-dom";
import LogoGreen from '../../components/LogoGreen';
import { ENDPOINT } from '../../variaveis/variaveis';
import { checkAccountController } from '../../controller/CheckAccountController/CheckAccountController';
import SignupForm from '../Signup/Signup';
import "react-toastify/dist/ReactToastify.css";
import { ToastContainer } from "react-toastify";
import { AccountInfoController } from '../../controller/AccountInfoController/AccountInfoController';

export default function LoginForm() {
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState('');
    const [step, setStep] = useState(1);
    const navigate = useNavigate();

    const handleLogin = async (e: FormEvent) => {
        e.preventDefault();
        setLoading(true);
        setError('');

        try {
            const response = await fetch(`${ENDPOINT}/login`, {
                method: 'POST',
                credentials: 'include',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify({ email, password }),
            });

            const data = await response.json();

            if (response.ok) {
                const info = await AccountInfoController();

                if (info.data.level>=2 &&info.data.status==='APPROVED') {
                    navigate('/')
                 } else {
                    navigate('/kyc')
                 }
                
                // Salvar o info em localStorage
                localStorage.setItem("accountInfo", JSON.stringify(info.data));
            
                console.log("info:", info.data);
            }
            
            else if (!response.ok) {
                setError(data.error);
                throw new Error(data.error || 'Error to login');
            }


        } catch (error) {
            if (error instanceof Error) {
                setError(error.message);
            } else {
                setError('Ocorreu um erro desconhecido');
            }
        } finally {
            setLoading(false);
        }
    };

    const handleVerifyEmail = async (e: FormEvent) => {
        e.preventDefault();
        setLoading(true);
        setError('');
        try {
            setLoading(true);
            const data = { email: email };
            const responseAPI = await checkAccountController(data);
            if (responseAPI.status !== 200) {
                setError(responseAPI.data.data.error);
            } else if (responseAPI.data) {
                setStep(2);
            } else {
                setStep(3);
            }
        } catch (error) {
            if (error instanceof Error) {
                setError(error.message);
            } else {
                setError('Ocorreu um erro desconhecido');
            }
        } finally {
            setLoading(false);
        }
    };

    const handleSubmit = async (e: FormEvent) => {
        e.preventDefault();
        await handleVerifyEmail(e);
        switch (step) {
            case 2:
                await handleLogin(e);
                break;
            default:
                console.log("Default case");
        }
    };

    
    return (
        <>
            {step <= 2 ? (
                <div className='flex justify-center items-center h-screen'>
                    <form onSubmit={handleSubmit} className="w-[382px] h-[463px] bg-white rounded-lg shadow-lg p-6">
                        <LogoGreen />
                        <span className="font-inter font-bold text-2xl tracking-tight text-gray-800 block mb-6 mt-10">Entre na sua conta</span>
                        <div className="flex items-center w-full h-[52px] gap-4 mb-4 bg-gray-100">
                            <FontAwesomeIcon icon={faEnvelope} className="h-4 w-4 ml-4 text-gray-500" />
                            <input type="email" className="form-input w-full py-2 rounded-md bg-gray-100 border-transparent ring-0 focus:border-transparent focus:ring-0" placeholder="Digite seu e-mail" onChange={(e) => setEmail(e.target.value)} />
                        </div>
                        {step === 2 && (
                            <>
                                <div className="flex items-center w-full h-[52px] gap-4 mb-4 bg-gray-100">
                                    <FontAwesomeIcon icon={faKey} className="h-4 w-4 ml-4 text-gray-500" />
                                    <input type="password" className="form-input w-full py-2 rounded-md bg-gray-100 border-transparent ring-0 focus:border-transparent focus:ring-0" placeholder="Digite sua senha" onChange={(e) => setPassword(e.target.value)} />
                                </div>
                            
                            </>
                        )}
                        <Button type="submit" disabled={loading || !email} className="w-full flex justify-center items-center px-5 py-1 bg-[#0A1F27] rounded-lg text-white mt-10">
                            {loading ? 'Loading...' : 'Entrar'}
                        </Button>
                        {error && <div className="text-red-500 mt-2">{error}</div>}
                    </form>
                </div>
            ) : (
                <SignupForm emailLogin={email} />
            )}
            <ToastContainer/>
        </>
    );
}
