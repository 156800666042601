import { Skeleton } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { FaMoneyBill, FaChartLine } from 'react-icons/fa';
import { useNavigate } from 'react-router-dom';
import { toast, ToastContainer } from "react-toastify";
import ErrorFallback from '../KYC/ErrorFallback';
import { AccountInfoController } from '../../controller/AccountInfoController/AccountInfoController';
import { BRLA_CONTRACT_ADDRESSES, POLYGONURL, TFTC_IMPACT_BRLA_V2_CONTRACT } from '../../variaveis/variaveis';
import { BRLAContractAbi, tftcAbi } from '../../utils/abis';
import { UnStakeFreeSharesController } from '../../controller/UnstakeFreeSharesController/UnStakeFreeSharesController';
import Web3 from 'web3';
import LogoGreen from '../../components/LogoGreen';

interface AccountInfo {
    id: string;
    email: string;
    walletAddress: string;
    createdAt: string;
    updatedAt: string;
    taxId: string;
    level: number;
    status: string;
}

interface StakeInfo {
    freeStakedSharesValueBrla: string;
    freeStakedShares: string;
}

const UnstakeFreeShares: React.FC = () => {
    const [disinvestAmountReais, setDisinvestAmountReais] = useState(''); // Valor em reais
    const [infoData, setInfoData] = useState<AccountInfo | null>(null);
    const [error, setError] = useState('');
    const [hasError, setHasError] = useState(false);
    const [balanceError, setBalanceError] = useState(false);
    const [isButtonDisabled, setIsButtonDisabled] = useState(true);
    const [isLoading, setIsLoading] = useState(false);
    const [isLoadingBalance, setIsLoadingBalance] = useState(true);
    const [nftFreeStakedShares, setNftFreeStakedShares] = useState<Array<{ nftId: number; shares: bigint }>>([]);
    const [totalFreeStakedValueReais, setTotalFreeStakedValueReais] = useState(0); // Saldo em reais
    const navigate = useNavigate();

    useEffect(() => {
        const amountReais = parseFloat(disinvestAmountReais);

        if (!isNaN(amountReais) && amountReais > 0) {
            if (amountReais <= totalFreeStakedValueReais) {
                setError('');
                setIsButtonDisabled(false);
            } else {
                setError('Por favor, insira uma quantidade válida dentro do saldo disponível.');
                setIsButtonDisabled(true);
            }
        } else {
            setError('Por favor, insira um valor válido.');
            setIsButtonDisabled(true);
        }
    }, [disinvestAmountReais, totalFreeStakedValueReais]);

    const handleDisinvest = async () => {
        const amountReais = parseFloat(disinvestAmountReais);
        if (isNaN(amountReais) || amountReais <= 0) {
            setError("Quantidade inválida para retirada.");
            return;
        }

        // Converte valor em reais para shares
        const totalBalanceInShares = nftFreeStakedShares.reduce((acc, nft) => acc + nft.shares, BigInt(0));
        const requestedShares = BigInt(Math.floor((amountReais / totalFreeStakedValueReais) * Number(totalBalanceInShares)));

        let remainingShares = requestedShares;

        const unstakeData = nftFreeStakedShares
            .filter(nft => remainingShares > 0n)
            .map(nft => {
                const amountToUnstake = remainingShares > nft.shares ? nft.shares : remainingShares;
                remainingShares -= amountToUnstake;
                return amountToUnstake > 0n ? { tokenId: nft.nftId, amountShares: amountToUnstake.toString() } : null;
            })
            .filter(Boolean);

        setIsLoading(true);
        try {
            for (const data of unstakeData) {
                const response = await UnStakeFreeSharesController(data as { tokenId: number; amountShares: string });
                if (response.status !== 201) {
                    toast.error("Erro ao enviar solicitação de retirada.");
                    break;
                }
            }

            if (remainingShares === 0n) {
                toast.success("Solicitação de retirada enviada com sucesso!");
                setTimeout(() => navigate("/"), 3000);
            } else {
                toast.error("Saldo insuficiente para completar a retirada.");
            }
        } catch (error: any) {
            if (error.status === 401) {
                navigate('/login');
            }
            toast.error("Erro na solicitação: " + (error.response?.data?.error || error.message));
        } finally {
            setIsLoading(false);
        }
    };

    const fetchAccountInfo = async () => {
        try {
            const info = await AccountInfoController();
            if (info && info.data) {
                setInfoData(info.data);
                setHasError(false);
            }
        } catch (error: any) {
            if (error.status === 401) {
                navigate('/login');
            } else {
                setHasError(true);
            }
        }
    };

    const fetchNFTBalances = async () => {
        if (!infoData?.walletAddress) return;

        const web3 = new Web3(new Web3.providers.HttpProvider(POLYGONURL));
        const tftcContract = new web3.eth.Contract(tftcAbi, TFTC_IMPACT_BRLA_V2_CONTRACT[137]);

        try {
            const nftQuantity = await tftcContract.methods.balanceOf(infoData.walletAddress).call();
            const nftBalances: Array<{ nftId: number; shares: bigint }> = [];
            let accumulatedFreeStakedValueReais = 0;

            for (let i = 0; i < Number(nftQuantity); i++) {
                const nftId = parseInt(await tftcContract.methods.tokenOfOwnerByIndex(infoData.walletAddress, i).call());
                const stakeInfo: StakeInfo = await tftcContract.methods.getStakeInfo(nftId).call();
                nftBalances.push({ nftId, shares: BigInt(stakeInfo.freeStakedShares) });
                accumulatedFreeStakedValueReais += parseFloat(web3.utils.fromWei(stakeInfo.freeStakedSharesValueBrla, 'ether'));
            }

            setNftFreeStakedShares(nftBalances);
            setTotalFreeStakedValueReais(accumulatedFreeStakedValueReais); // Exibir saldo em reais
            setBalanceError(false);
        } catch (error) {
            console.error("Error fetching NFT balances:", error);
            setBalanceError(true);
        } finally {
            setIsLoadingBalance(false);
        }
    };

    useEffect(() => {
        fetchAccountInfo();
    }, [navigate]);

    useEffect(() => {
        if (infoData) {
            fetchNFTBalances();
        }
    }, [infoData]);

    if (!infoData || isLoadingBalance) {
        return (
            <div className="min-h-screen flex items-center justify-center">
                <Skeleton />
            </div>
        );
    }

    if (hasError || balanceError) {
        return <ErrorFallback onRetry={() => { fetchAccountInfo(); fetchNFTBalances(); }} />;
    }

    return (
        <div className="min-h-screen flex flex-col items-center justify-center bg-gray-100">
            <div className="max-w-3xl w-full bg-white rounded-lg shadow-lg p-8 grid grid-cols-1 gap-8">
                <button 
                    onClick={() => navigate('/')} 
                    className="self-start mb-4 text-gray-700 hover:text-gray-900"
                >
                    <FaChartLine className="inline-block mr-2" />
                    <span>Voltar para o Dashboard</span>
                </button>

                <div className="mx-auto">
                    <LogoGreen />
                </div>
                <div className="bg-gray-50 rounded-lg p-6 shadow-sm flex flex-col justify-between">
                    <h2 className="text-xl font-semibold text-gray-800 text-center mb-4">Retirar Saldo</h2>
                    <div className="mb-6 text-center">
                        <span className="text-gray-600">Saldo Disponível</span>
                        <h3 className="text-2xl font-bold text-gray-800 mt-1">
                            R$ {(Math.floor(totalFreeStakedValueReais * 100) / 100).toFixed(2)}
                        </h3>
                    </div>

                    <div className="space-y-4">
                        <label className="block text-gray-700 mb-1">Valor em reais para retirar</label>
                        <div className="flex items-center bg-white border border-gray-300 rounded p-2">
                            <FaMoneyBill className="text-gray-500 mr-2" />
                            <input 
                                type="number" 
                                placeholder="Insira o valor em reais" 
                                className="w-full bg-white outline-none" 
                                value={disinvestAmountReais} 
                                onChange={(e) => setDisinvestAmountReais(e.target.value)}
                            />
                        </div>
                        {error && <p className="text-red-500 text-sm text-center mt-4">{error}</p>}
                    </div>

                    <button 
                        onClick={handleDisinvest}
                        className={`w-full bg-[#00dc84] text-white py-2 rounded-lg mt-6 hover:bg-green-700 transition ${isButtonDisabled || isLoading ? 'cursor-not-allowed' : ''}`}
                        disabled={isButtonDisabled || isLoading}
                    >
                        {isLoading ? 'Carregando...' : 'Confirmar retirada'}
                    </button>
                </div>
            </div>
            <ToastContainer />
        </div>
    );
};

export default UnstakeFreeShares;
