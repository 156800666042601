import { http } from "../ConnectApi/ConnectApi";

export async function UnstakeLockedSharesHistoryController() {
  

  try {
    const response = await http.get(`tftc/blockchain/unstake-locked-shares/history`, {
      withCredentials: true
    });

    
    return response;
  } catch (error: any) {
    console.error('Error during register:', error.response?.data || error.message);
    throw error;
  }
}
