import { http } from "../ConnectApi/ConnectApi";

export async function ChangePasswordController(data: {
    currentPassword: string;
    newPassword: string;
    newPasswordConfirm: string;

}) {
  try {
    const response = await http.patch('tftc/change-password', data, {
      withCredentials: true
    });

    return response;
  } catch (error: any) {
    console.error('Error during request:', error.response?.data || error.message);
    throw error;
  }
}
