import { http } from "../ConnectApi/ConnectApi";

export async function checkAccountController(data: { email:string}) {
  try {
    const response = await http.post('tftc/check-email', data, {
    });

    return response;
  } catch (error: any) {
    console.error('Error during request:', error.response?.data || error.message);
    throw error;
  }
}
