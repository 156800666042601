import { http } from "../ConnectApi/ConnectApi";

export async function StakeController(data: { amountBrla: Number, freePercentage: Number  }) {
  try {
    const response = await http.post('tftc/blockchain/stake', data, {
      withCredentials: true
    });

    return response;
  } catch (error: any) {
    console.error('Error during request:', error.response?.data || error.message);
    throw error;
  }
}
