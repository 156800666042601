import React, { useState, useEffect } from 'react';
import { FaLock, FaArrowLeft } from 'react-icons/fa';
import { useNavigate } from 'react-router-dom';
import { toast, ToastContainer } from "react-toastify";
import LogoGreen from '../../components/LogoGreen';
import { ChangePasswordController } from '../../controller/ChangePasswordController/ChangePasswordController';

const ChangePassword: React.FC = () => {
    const [currentPassword, setCurrentPassword] = useState('');
    const [newPassword, setNewPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const [error, setError] = useState<string[]>([]);
    const [isButtonDisabled, setIsButtonDisabled] = useState(true);
    const [isLoading, setIsLoading] = useState(false);
    const navigate = useNavigate();

    const validatePassword = (password: string, confirmPassword: string): string[] => {
        const errors: string[] = [];
        if (password === '') errors.push("A senha não pode estar vazia!");
        if (!/[!@#%&*]/.test(password)) errors.push("Use pelo menos um caractere especial (@!#%&*)");
        if (!/[A-Z]/.test(password)) errors.push("Use pelo menos uma letra maiúscula");
        if (password.length < 8) errors.push("A senha deve ter pelo menos 8 caracteres");
        if (password !== confirmPassword) errors.push("As senhas não coincidem!");
        return errors;
    };

    const handlePasswordChange = async () => {
        if (!currentPassword || !newPassword || !confirmPassword) {
            setError(["Por favor, preencha todos os campos."]);
            return;
        }

        const validationErrors = validatePassword(newPassword, confirmPassword);
        if (validationErrors.length > 0) {
            setError(validationErrors);
            return;
        }

        setIsLoading(true);
        
        try {
            const data = {
                currentPassword: currentPassword,
                newPassword: newPassword,
                newPasswordConfirm: confirmPassword,
            };

            const response = await ChangePasswordController(data);
            if (response.status === 200) {
                toast.success("Senha alterada com sucesso!");
                setTimeout(() => navigate("/"), 3000);
            }
            
            else {
                toast.error("Falha ao trocar senha.");
            }
        } catch (error: any) {
            if (error.status===401) {
                navigate('/')
            }
            toast.error('Erro ao trocar senha: ' + (error.response?.data?.error || error.message));
        } finally {
            setIsLoading(false);
        }
    };

    useEffect(() => {
        const validationErrors = validatePassword(newPassword, confirmPassword);
        setError(validationErrors);
        setIsButtonDisabled(validationErrors.length > 0 || !currentPassword);
    }, [newPassword, confirmPassword, currentPassword]);

    return (
        <div className="min-h-screen flex flex-col items-center justify-center bg-gray-50">
            <div className="w-full max-w-md mb-4 flex items-center">
                <button onClick={() => navigate('/')} className="flex items-center text-gray-700 hover:text-gray-900">
                    <FaArrowLeft className="mr-2" />
                    <span>Voltar para o Dashboard</span>
                </button>
            </div>

            <div className="max-w-md w-full bg-white rounded-lg shadow-lg p-6">
                <div className="my-4">             
                    <LogoGreen />
                </div>
                <h2 className="text-2xl font-semibold text-gray-700 text-center mb-6">Alterar Senha</h2>
            

                <div className="space-y-4">
                    {/* Senha Atual */}
                    <div>
                        <label className="block text-gray-600 mb-1">Senha Atual</label>
                        <div className="flex items-center bg-gray-100 rounded p-2">
                            <FaLock className="text-gray-500 mr-2" />
                            <input
                                type="password"
                                placeholder="Digite sua senha atual"
                                className="w-full bg-gray-100 outline-none"
                                value={currentPassword}
                                onChange={(e) => setCurrentPassword(e.target.value)}
                            />
                        </div>
                    </div>

                    {/* Nova Senha */}
                    <div>
                        <label className="block text-gray-600 mb-1">Nova Senha</label>
                        <div className="flex items-center bg-gray-100 rounded p-2">
                            <FaLock className="text-gray-500 mr-2" />
                            <input
                                type="password"
                                placeholder="Digite a nova senha"
                                className="w-full bg-gray-100 outline-none"
                                value={newPassword}
                                onChange={(e) => setNewPassword(e.target.value)}
                            />
                        </div>
                    </div>

                    {/* Confirmar Nova Senha */}
                    <div>
                        <label className="block text-gray-600 mb-1">Confirmar Nova Senha</label>
                        <div className="flex items-center bg-gray-100 rounded p-2">
                            <FaLock className="text-gray-500 mr-2" />
                            <input
                                type="password"
                                placeholder="Confirme a nova senha"
                                className="w-full bg-gray-100 outline-none"
                                value={confirmPassword}
                                onChange={(e) => setConfirmPassword(e.target.value)}
                            />
                        </div>
                    </div>

                    {/* Botão de Enviar */}
                    <button
                        onClick={handlePasswordChange}
                        className={`w-full py-2 rounded-lg mt-6 transition ${isButtonDisabled || isLoading ? 'bg-[#00dc84] cursor-not-allowed' : 'bg-[#00dc84] text-white hover:bg-green-700'}`}
                        disabled={isButtonDisabled || isLoading}
                    >
                        {isLoading ? 'Carregando...' : 'Alterar Senha'}
                    </button>
                    {error.length > 0 && (
                    <ul className="text-red-500 text-sm mb-4 list-disc list-inside">
                        {error.map((err, index) => (
                            <li key={index}>{err}</li>
                        ))}
                    </ul>
                )}
                </div>
            </div>
            <ToastContainer />
        </div>
    );
};

export default ChangePassword;
