import React, { useEffect, useState } from 'react';
import { FaEnvelope, FaWallet, FaCalendarAlt, FaIdCard, FaLevelUpAlt, FaCheckCircle, FaArrowLeft, FaCopy } from 'react-icons/fa';
import { useNavigate } from 'react-router-dom';
import { AccountInfoController } from '../../controller/AccountInfoController/AccountInfoController';
import { KYCLevel3Controller } from '../../controller/KYCLevel3Controller/KYCLevel3Controller';
import { Skeleton } from '@mui/material';
import LogoGreen from '../../components/LogoGreen';
import { toast } from 'react-toastify';

interface ProfileData {
    email: string;
    walletAddress: string;
    createdAt: string;
    updatedAt: string;
    taxId: string;
    level: number;
    status: string;
}

const Profile: React.FC = () => {
    const [profileData, setProfileData] = useState<ProfileData | null>(null);
    const [hasError, setHasError] = useState(false);
    const [isQualifiedInvestor, setIsQualifiedInvestor] = useState(false);
    const [isCheckboxChecked, setIsCheckboxChecked] = useState(false); // Verificação do checkbox
    const [isDeclarationChecked, setIsDeclarationChecked] = useState(false); // Verificação da declaração
    const navigate = useNavigate();

    useEffect(() => {
        const fetchProfileData = async () => {
            try {
                const response = await AccountInfoController();
                if (response && response.data) {
                    setProfileData(response.data);
                    setIsQualifiedInvestor(response.data.level === 3); 
                    setIsCheckboxChecked(response.data.level === 3)
                }
            } catch (error) {
                setHasError(true);
                console.error("Error fetching profile data:", error);
            }
        };
        fetchProfileData();
    }, []);

    const handleCopyWallet = () => {
        if (profileData?.walletAddress) {
            navigator.clipboard.writeText(profileData.walletAddress);
            toast.success("Endereço da wallet copiado!");
        }
    };

    const formatDateTime = (dateString: string) => {
        const date = new Date(dateString);
        return date.toLocaleString("pt-BR", { dateStyle: "short", timeStyle: "medium" });
    };

    const handleConfirmQualifiedInvestor = async () => {
        try {
            const data = {}
            const response = await KYCLevel3Controller(data);
            if (response && (response.status === 200 || response.status === 201)) {
                toast.success("Confirmado como investidor qualificado!");
                setIsQualifiedInvestor(true); // Atualiza o estado para o checkbox marcado
            }
        } catch (error) {
            toast.error("Erro ao confirmar investidor qualificado.");
            console.error("Error confirming qualified investor:", error);
        }
    };

    if (hasError) {
        return <p className="text-center text-red-500">Erro ao carregar as informações de perfil.</p>;
    }

    if (!profileData) {
        return (
            <div className="min-h-screen flex items-center justify-center">
                <Skeleton variant="rectangular" width={400} height={300} />
            </div>
        );
    }

    return (
        <div className="min-h-screen flex flex-col items-center bg-gray-100 py-8">
            <div className="w-full max-w-lg flex items-start mb-6">
                <button onClick={() => navigate('/')} className="flex items-center text-gray-700 hover:text-gray-900">
                    <FaArrowLeft className="mr-2" />
                    <span>Voltar para o Dashboard</span>
                </button>
            </div>

            <div className="bg-white shadow-lg rounded-lg p-8 w-full max-w-lg">
                <div className="py-4 text-center">
                    <LogoGreen />
                </div>
                <h1 className="text-3xl font-bold text-gray-800 mb-6 mt-4">Perfil do Usuário</h1>

                {/* Email */}
                <div className="flex items-center mb-4">
                    <FaEnvelope className="text-gray-500 mr-3" />
                    <span className="text-gray-700 font-semibold">Email:</span>
                    <span className="ml-auto text-gray-900">{profileData.email}</span>
                </div>

                {/* Wallet Address */}
                <div className="flex items-center mb-4">
                    <FaWallet className="text-gray-500 mr-3" />
                    <span className="text-gray-700 font-semibold">Wallet:</span>
                    <span
                        className="ml-auto text-gray-900 cursor-pointer flex items-center"
                        onClick={handleCopyWallet}
                        title="Clique para copiar"
                    >
                        {`${profileData.walletAddress.slice(0, 3)}...${profileData.walletAddress.slice(-3)}`}
                        <FaCopy className="ml-2 text-gray-400 hover:text-gray-600" />
                    </span>
                </div>

                {/* Created At */}
                <div className="flex items-center mb-4">
                    <FaCalendarAlt className="text-gray-500 mr-3" />
                    <span className="text-gray-700 font-semibold">Criado em:</span>
                    <span className="ml-auto text-gray-900">{formatDateTime(profileData.createdAt)}</span>
                </div>

                {/* Updated At */}
                <div className="flex items-center mb-4">
                    <FaCalendarAlt className="text-gray-500 mr-3" />
                    <span className="text-gray-700 font-semibold">Atualizado em:</span>
                    <span className="ml-auto text-gray-900">{formatDateTime(profileData.updatedAt)}</span>
                </div>

                {/* Tax ID */}
                <div className="flex items-center mb-4">
                    <FaIdCard className="text-gray-500 mr-3" />
                    <span className="text-gray-700 font-semibold">CPF:</span>
                    <span className="ml-auto text-gray-900">{profileData.taxId}</span>
                </div>

                {/* Level */}
                <div className="flex items-center mb-4">
                    <FaLevelUpAlt className="text-gray-500 mr-3" />
                    <span className="text-gray-700 font-semibold">Nível:</span>
                    <span className="ml-auto text-gray-900">{profileData.level}</span>
                </div>

                {/* Status */}
                <div className="flex items-center mb-4">
                    <FaCheckCircle className="text-gray-500 mr-3" />
                    <span className="text-gray-700 font-semibold">Status:</span>
                    <span className="ml-auto text-gray-900">{profileData.status}</span>
                </div>

                {/* Qualified Investor Declaration */}
                <div className="flex flex-col items-start mb-4">
                    <label className="flex items-center text-gray-700 font-semibold">
                        <input
                            type="checkbox"
                            className="mr-2"
                            checked={isCheckboxChecked}
                            onChange={(e) => setIsCheckboxChecked(e.target.checked)}
                            disabled={isQualifiedInvestor} // Desabilita o checkbox se já for investidor qualificado
                        />
                        Sou um investidor qualificado
                    </label>
               

                    {isCheckboxChecked && !isQualifiedInvestor && (
                        <div className="mt-4">
                            <h3 className="text-gray-700 font-semibold mb-2">Declaração de Investidor Qualificado:</h3>
                            <p className="text-gray-600 text-sm mb-4">
                                Declaro, para os devidos fins e em conformidade com a Instrução CVM nº 554, de 17 de dezembro de 2014, da Comissão de Valores Mobiliários (CVM), que:
                                <br /><br />
                                Compreendo os riscos associados aos investimentos realizados e estou ciente de que, como investidor qualificado, posso ter acesso a produtos e operações que envolvem riscos específicos e que podem não estar disponíveis para o público em geral.
                                <br /><br />
                                Confirmo que atendo a pelo menos uma das condições estabelecidas no artigo 9º-A da referida instrução, sendo elas:
                                <br />
                                • Possuir patrimônio investido de, no mínimo, R$ 1.000.000,00 (um milhão de reais) e/ou
                                <br />
                                • Possuir certificação profissional emitida por entidade reconhecida pela CVM.
                                <br /><br />
                                Reconheço e aceito que, ao optar por produtos e operações destinados a investidores qualificados, estou assumindo a responsabilidade integral pelas decisões de investimento e seus resultados, ciente das eventuais oscilações e perdas que possam decorrer.
                                <br /><br />
                                Assumo a responsabilidade pela veracidade das informações fornecidas nesta declaração e compreendo que a falsidade de qualquer informação pode resultar em sanções aplicáveis.
                            </p>
                            <label className="flex items-center text-gray-700 font-semibold">
                                <input
                                    type="checkbox"
                                    className="mr-2"
                                    checked={isDeclarationChecked}
                                    onChange={(e) => setIsDeclarationChecked(e.target.checked)}
                                />
                                Li e aceito a declaração acima
                            </label>
                            {isDeclarationChecked && (
                                <button
                                    onClick={handleConfirmQualifiedInvestor}
                                    className="w-full py-2 rounded-lg mt-6 transition  bg-[#00dc84] text-white hover:bg-green-700"
                                >
                                    Confirmar como investidor qualificado
                                </button>
                            )}
                        </div>
                    )}
                </div>
            </div>
        </div>
    );
};

export default Profile;
