import React, { useState, useRef, useEffect } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSpinner, faCheckCircle, faUpload, faChevronDown } from '@fortawesome/free-solid-svg-icons';
import { Button } from 'flowbite-react';
import LogoGreen from '../../components/LogoGreen';
import { DocumentInputController } from '../../controller/DocumentUploadInputController/DocumentInputController';
import { useNavigate } from 'react-router-dom';
import KYCStatus from './KYCStatus';
import { AccountInfoController } from '../../controller/AccountInfoController/AccountInfoController';

interface FileUploadFormProps {
    title: string;
}

export default function FileUploadForm({ title }: FileUploadFormProps) {
    const [error, setError] = useState('');
    const navigate = useNavigate();
    const [status, setStatus] = useState<'initial' | 'PROCESSING' | 'APPROVED' | 'REJECTED'>('initial');
    const [uploadedItems, setUploadedItems] = useState({
        selfie: false,
        rgFront: false,
        rgBack: false,
        cnh: false,
    });
    const [uploadingItemsMap, setUploadingItemsMap] = useState({
        selfie: false,
        rgFront: false,
        rgBack: false,
        cnh: false,
    });
    const [fileInputs, setFileInputs] = useState<{ [key: string]: File | null }>({});
    const [selectedDocumentType, setSelectedDocumentType] = useState('RG');
    const fileInputRefs = useRef<{ [key: string]: HTMLInputElement | null }>({});

    // Verifica se todos os arquivos necessários foram selecionados
    const isReadyToUpload = () => {
        if (selectedDocumentType === 'RG') {
            return fileInputs['rgFront'] && fileInputs['rgBack'] && fileInputs['selfie'];
        }
        return fileInputs['cnh'] && fileInputs['selfie'];
    };

    // Realiza o upload dos arquivos e inicia o polling após status 201
    const handleUploadFiles = async () => {
        if (!isReadyToUpload()) return;

        try {
            const data = { documentType: selectedDocumentType };
            const uploadUrlResponse = await DocumentInputController(data);
           


            const uploadUrls = {
                front: uploadUrlResponse.data.CNHUploadUrl || uploadUrlResponse.data.RGFrontUploadUrl ,
                back: uploadUrlResponse.data.RGBackUploadUrl,
                selfie: uploadUrlResponse.data.selfieUploadUrl,
            };

           


            if (selectedDocumentType === 'RG') {
                await uploadFile(uploadUrls.front, 'rgFront');
                await uploadFile(uploadUrls.back, 'rgBack');
            } else {
                await uploadFile(uploadUrls.front, 'cnh');
            }
            await uploadFile(uploadUrls.selfie, 'selfie');

           

            if (uploadUrlResponse.status === 201) {
                setStatus('PROCESSING'); // Define o status para 'PROCESSING' ao receber 201
                startPolling(); // Inicia o polling para verificar o status
            }

        } catch (uploadError: any) {
            if (uploadError.status === 401) {
                navigate('/login');
            } else {
                setError('Erro ao fazer o upload dos arquivos. Tente novamente.');
            }
        }
    };

    // Função para upload de arquivo individual
    const uploadFile = async (url: string, documentKey: 'selfie' | 'rgFront' | 'rgBack' | 'cnh') => {
        if (fileInputs[documentKey]) {
            try {
                await fetch(url, {
                    method: 'PUT',
                    body: fileInputs[documentKey]!,
                    headers: { 'Content-Type': 'application/octet-stream' },
                });
                setUploadedItems((prev) => ({ ...prev, [documentKey]: true }));
            } catch {
                setError(`Erro ao fazer o upload do documento: ${documentKey}`);
            } finally {
                setUploadingItemsMap((prev) => ({ ...prev, [documentKey]: false }));
            }
        }
    };

    // Atualiza o estado do botão assim que o arquivo é carregado no front-end
    const onFileChange = (event: React.ChangeEvent<HTMLInputElement>, documentKey: 'selfie' | 'rgFront' | 'rgBack' | 'cnh') => {
        if (event.target.files && event.target.files[0]) {
            const selectedFile = event.target.files[0];
            setFileInputs((prev) => ({ ...prev, [documentKey]: selectedFile }));
            setUploadingItemsMap((prev) => ({ ...prev, [documentKey]: true }));
            setTimeout(() => {
                setUploadedItems((prev) => ({ ...prev, [documentKey]: true }));
                setUploadingItemsMap((prev) => ({ ...prev, [documentKey]: false }));
            }, 500);
        }
    };

    // Polling para verificar o status do KYC após 201
    const startPolling = () => {
        const intervalId = setInterval(async () => {
            try {
                const response = await AccountInfoController();
                const currentStatus = response?.data?.status;
    
                if (currentStatus !== 'PROCESSING') {
                    setStatus(currentStatus);
                    clearInterval(intervalId);
                }
            } catch (error: any) {
                if (error.response?.status === 401) {
                    clearInterval(intervalId); // Para o polling
                    navigate('/login'); // Redireciona para /login
                }
            }
        }, 5000);
    };

    useEffect(() => {
        if (isReadyToUpload()) {
            handleUploadFiles();
        }
    }, [fileInputs]);




    const isDocumentUploading = (documentKey: 'selfie' | 'rgFront' | 'rgBack' | 'cnh') => uploadingItemsMap[documentKey];
    const isDocumentUploaded = (documentKey: 'selfie' | 'rgFront' | 'rgBack' | 'cnh') => uploadedItems[documentKey];
    function KYCClick() {
        if (status==='APPROVED') {
            navigate('/')
        } else {
            window.location.reload();
        }
        
    }
    
    if (status === 'PROCESSING') {
        return <KYCStatus status="pending" onClick={KYCClick} />;
    }

    if (status === 'APPROVED') {
        return <KYCStatus status="success" onClick={KYCClick} />;
    }

    if (status === 'REJECTED') {
        return <KYCStatus status="failed" onClick={KYCClick}/>;
    }

    

    return (
        <form className="w-[95%] md:w-[340px] bg-white rounded-lg shadow-md p-3 relative z-10 mx-auto mt-8 flex flex-col max-h-[470px]">
            <LogoGreen />
            <span className="font-inter font-bold text-xl tracking-tight text-gray-800 block mb-2 mt-3 text-center">
                {title}
            </span>

            {/* Selector for Document Type */}
            <div className="mb-4">
                <label className="block text-gray-700 font-medium mb-1 text-sm">
                    Selecione o tipo de documento:
                </label>
                <div className="relative">
                    <select
                        value={selectedDocumentType}
                        onChange={(e) => setSelectedDocumentType(e.target.value)}
                        className="w-full p-2 bg-white border border-gray-300 rounded-lg shadow-sm focus:outline-none focus:ring-2 focus:ring-green-500 focus:border-transparent text-sm text-gray-800 cursor-pointer appearance-none transition-all"
                    >
                        <option value="RG">RG</option>
                        <option value="CNH">CNH</option>
                    </select>
                    <FontAwesomeIcon
                        icon={faChevronDown}
                        className="absolute right-3 top-1/2 transform -translate-y-1/2 text-gray-500 pointer-events-none"
                    />
                </div>
            </div>

            {/* Selfie Upload */}
            <UploadButton
                label="Selfie"
                documentKey="selfie"
                isUploading={isDocumentUploading('selfie')}
                isUploaded={isDocumentUploaded('selfie')}
                onFileChange={onFileChange}
            />

            {selectedDocumentType === 'RG' ? (
                <>
                    <UploadButton
                        label="RG Frente"
                        documentKey="rgFront"
                        isUploading={isDocumentUploading('rgFront')}
                        isUploaded={isDocumentUploaded('rgFront')}
                        onFileChange={onFileChange}
                    />
                    <UploadButton
                        label="RG Trás"
                        documentKey="rgBack"
                        isUploading={isDocumentUploading('rgBack')}
                        isUploaded={isDocumentUploaded('rgBack')}
                        onFileChange={onFileChange}
                    />
                </>
            ) : (
                <UploadButton
                    label="CNH"
                    documentKey="cnh"
                    isUploading={isDocumentUploading('cnh')}
                    isUploaded={isDocumentUploaded('cnh')}
                    onFileChange={onFileChange}
                />
            )}

{isReadyToUpload() && (
        <p className="text-sm text-gray-500 mt-2 text-center">Por favor, aguarde...</p>
    )}

            {error && <div className="text-red-500 mt-2 text-xs">{error}</div>}
        </form>
    );
}

interface UploadButtonProps {
    label: string;
    documentKey: 'selfie' | 'rgFront' | 'rgBack' | 'cnh';
    isUploading: boolean;
    isUploaded: boolean;
    onFileChange: (event: React.ChangeEvent<HTMLInputElement>, documentKey: 'selfie' | 'rgFront' | 'rgBack' | 'cnh') => void;
}

const UploadButton: React.FC<UploadButtonProps> = ({ label, documentKey, isUploading, isUploaded, onFileChange }) => {
    const fileInputRef = useRef<HTMLInputElement | null>(null);
    return (
        <div className="flex justify-between items-center bg-gray-50 p-2 rounded-lg border border-gray-300 mb-4">
            <div className="flex items-center ml-2">
                <FontAwesomeIcon icon={faUpload} className="text-green-500 mr-2" />
                <span className="font-medium text-gray-700 text-sm">{label}</span>
            </div>
            <Button
                onClick={() => fileInputRef.current?.click()}
                className={`bg-black ${ isUploaded ? 'text-green-500':'text-white'} px-1 py-1 flex items-center justify-center hover:bg-gray-800 transition-all text-xs
                }`}
                disabled={isUploading}
            >
                <FontAwesomeIcon
                    icon={isUploading ? faSpinner : isUploaded ? faCheckCircle : faUpload}
                    className={isUploaded ? 'text-green' : ''}
                    spin={isUploading}
                />
            </Button>
            <input
                type="file"
                onChange={(e) => onFileChange(e, documentKey)}
                className="hidden"
                ref={fileInputRef}
                accept=".png, .jpeg, .pdf"
            />
        </div>
    );
};
