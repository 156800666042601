export default function LogoBrla() {
    return (
      <svg
      className="mx-auto mt-2"
      width={200}
      height={33}
      viewBox="0 0 369 96"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
     
    >
      <path
        d="M130.499 78.6225V16.4592H160.478C167.179 16.4592 172.352 17.9288 175.997 20.868C179.7 23.7483 181.552 27.628 181.552 32.507C181.552 35.7401 180.729 38.5617 179.083 40.9718C177.496 43.3819 175.262 45.1748 172.382 46.3505C175.85 47.4086 178.525 49.2015 180.406 51.7291C182.287 54.198 183.227 57.2842 183.227 60.9875C183.227 66.4543 181.258 70.7749 177.319 73.9492C173.44 77.0647 168.12 78.6225 161.36 78.6225H130.499ZM146.017 41.1482H157.568C160.507 41.1482 162.653 40.6191 164.005 39.561C165.416 38.5029 166.121 36.8864 166.121 34.7114C166.121 32.5364 165.416 30.9493 164.005 29.95C162.653 28.8919 160.507 28.3628 157.568 28.3628H146.017V41.1482ZM146.017 66.7189H159.155C162.153 66.7189 164.328 66.1604 165.68 65.0435C167.091 63.8679 167.797 62.1338 167.797 59.8412C167.797 57.5487 167.091 55.844 165.68 54.7271C164.328 53.6102 162.153 53.0518 159.155 53.0518H146.017V66.7189Z"
        fill="#0A1F27"
      />
      <path
        d="M192.41 78.6225V16.4592H222.302C229.356 16.4592 234.793 18.1933 238.614 21.6615C242.494 25.071 244.434 29.8324 244.434 35.9459C244.434 40.5897 243.258 44.4988 240.907 47.6731C238.614 50.7886 235.263 52.9636 230.855 54.198C234.147 55.0798 236.792 57.3429 238.79 60.9875L248.49 78.6225H230.678L219.921 58.8713C219.216 57.6369 218.304 56.7551 217.188 56.2261C216.129 55.697 214.895 55.4325 213.484 55.4325H208.635V78.6225H192.41ZM208.635 43.9698H219.392C225.799 43.9698 229.003 41.4421 229.003 36.3867C229.003 31.3902 225.799 28.8919 219.392 28.8919H208.635V43.9698Z"
        fill="#0A1F27"
      />
      <path
        d="M254.667 78.6225V16.4592H270.891V65.1317H298.578V78.6225H254.667Z"
        fill="#0A1F27"
      />
      <path
        d="M297.976 78.6225L327.073 16.4592H339.859L368.956 78.6225H352.732L347.354 66.0135H319.578L314.2 78.6225H297.976ZM333.334 33.3888L324.869 53.5808H341.975L333.51 33.3888H333.334Z"
        fill="#0A1F27"
      />
      <circle cx="47.6144" cy="47.6144" r="47.6144" fill="#0A1F27" />
      <path
        d="M23.5133 21.7498V57.7257H35.2437V33.3616H58.1854C59.2235 33.3616 60.6768 34.8131 60.6768 36.5756C60.6768 37.9856 59.0159 38.9601 58.1854 39.2712H41.057C41.057 54.3043 43.8598 56.9999 45.5207 60.2139C52.2475 71.0793 66.1786 73.1735 72.3034 72.8625V61.147C64.2063 60.5249 64.7253 60.3176 60.6768 58.6587C57.438 57.3317 54.9673 52.922 54.1368 50.883H59.5349C63.791 50.4337 72.3034 46.9433 72.3034 36.5756C72.3034 26.2079 63.791 22.3718 59.5349 21.7498H23.5133Z"
        fill="#00DD85"
      />
      <path
        d="M60.6767 58.6588C57.4379 57.3318 54.9672 52.922 54.1368 50.8831L41.0569 39.2712C41.0569 54.3044 43.8597 57 45.5207 60.214C52.2475 71.0793 66.1786 73.1736 72.3033 72.8626V61.1471C64.2062 60.525 64.7252 60.3177 60.6767 58.6588Z"
        fill="#00DD85"
      />
    </svg>
    
  
    );
  }
  