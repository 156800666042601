import { http } from "../ConnectApi/ConnectApi";

export async function KYCLevel3Controller(data: {  }) {
  try {
    const response = await http.post('tftc/kyc/level3', data, {
      withCredentials: true
    });

    return response;
  } catch (error: any) {
    console.error('Error during request:', error.response?.data || error.message);
    throw error;
  }
}
