import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import BasicKYC from './BasicKYC';
import { AccountInfoController } from '../../controller/AccountInfoController/AccountInfoController';
import ErrorFallback from './ErrorFallback';
import FileUploadForm from './KYCLevel2';
import KYCStatus from './KYCStatus';
import { Skeleton } from '@mui/material';

interface AccountInfo {
    id: string;
    email: string;
    walletAddress: string;
    createdAt: string;
    updatedAt: string;
    taxId: string;
    level: number;
    status: string;
}

const KYC: React.FC = () => {
    const [hasError, setHasError] = useState(false);
    const [infoData, setInfoData] = useState<AccountInfo | null>(null);
    const [status, setStatus] = useState<string>('');
    const [sessionActive, setSessionActive] = useState<boolean>(false);
    const navigate = useNavigate();

    const fetchAccountInfo = async () => {
        try {
            const info = await AccountInfoController();
            if (info && info.data) {
                setInfoData(info.data);
                setStatus(info.data.status);
                setHasError(false);
            }
        } catch (error: any) {
            if (error.status === 401) {
                navigate('/login');
            } else {
                setHasError(true);
            }
        }
    };

    useEffect(() => {
        fetchAccountInfo();
    }, [navigate]);

    useEffect(() => {
        if (status === 'PROCESSING') {
            setSessionActive(true);
            const intervalId = setInterval(() => {
                fetchAccountInfo();
            }, 5000);

            return () => clearInterval(intervalId);
        }
    }, [status]);

    if (!infoData) {
        return (
            <div className="min-h-screen flex items-center justify-center">
                <Skeleton/>
            </div>
        );
    }

    if (hasError) {
        return <ErrorFallback onRetry={fetchAccountInfo} />;
    }

    function KYCClick() {
        if (infoData && infoData.level >= 2 && status === 'APPROVED') {
            navigate('/');
        } else {
            window.location.reload();
        }
    }

    if (status === 'PROCESSING') {
        return (
            <div className="min-h-screen flex items-center justify-center">
                <KYCStatus status="pending" onClick={KYCClick} />
            </div>
        );
    }

    if (status === 'APPROVED') {
        return sessionActive ? (
            <div className="min-h-screen flex items-center justify-center">
                <KYCStatus status="success" onClick={KYCClick} />
            </div>
        ) :
        
            infoData.level >= 2 && infoData.status === 'APPROVED' ?
            <KYCStatus status='success' onClick={KYCClick}/> :
        (
            <div className="flex justify-center items-center h-screen">
                {infoData.level === 0 || (infoData.level === 0 && infoData.status === 'REJECTED') ? (
                    <BasicKYC />
                ) : (
                    <FileUploadForm title="Verificação avançada" />
                )}
            </div>
        );
    }

    if (status === 'REJECTED') {
        return sessionActive ? (
            <div className="min-h-screen flex items-center justify-center">
                <KYCStatus status="failed" onClick={KYCClick} />
            </div>
        ) : (
            <div className="flex justify-center items-center h-screen">
                {infoData.level === 0 || (infoData.level === 0 && infoData.status === 'REJECTED') ? (
                    <BasicKYC />
                ) : (
                    <FileUploadForm title="Verificação avançada" />
                )}
            </div>
        );
    }

    return (
        <div className="flex justify-center items-center h-screen">
            {infoData.level === 0 || (infoData.level === 0 && infoData.status === 'REJECTED') ? (
                <BasicKYC />
            ) : 
            infoData.level >= 2 && infoData.status === 'APPROVED' ?
            <KYCStatus status='success' onClick={KYCClick}/> :
            (
                <FileUploadForm title="Verificação avançada" />
            )}
        </div>
    );
};

export default KYC;
