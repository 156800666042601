import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faExclamationTriangle, faRedo } from '@fortawesome/free-solid-svg-icons';
import {Button} from 'flowbite-react'
interface ErrorFallbackProps {
    onRetry: () => void;
}

const ErrorFallback: React.FC<ErrorFallbackProps> = ({ onRetry }) => {
    return (
        <div className="bg-white border border-red-200 rounded-lg p-6 shadow-md hover:shadow-lg transition duration-300 ease-in-out flex flex-col items-center justify-center">
            <FontAwesomeIcon icon={faExclamationTriangle} className="text-red-500 text-4xl mb-4" />
            <h2 className="text-xl font-bold text-red-500 mb-4">Ocorreu um erro ao carregar os dados.</h2>
            <Button
                className="bg-red-500 text-white px-4 py-2 rounded-lg"
                onClick={onRetry}
            >
                <FontAwesomeIcon icon={faRedo} className="mr-2" />
                Tentar novamente
            </Button>
        </div>
    );
};

export default ErrorFallback;
