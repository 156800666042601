import React, { useState, FormEvent, useEffect } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEnvelope, faKey, faFile, faTimes } from '@fortawesome/free-solid-svg-icons';
import { Button } from 'flowbite-react';
import { useNavigate } from "react-router-dom";
import LogoGreen from '../../components/LogoGreen';
import { ENDPOINT } from '../../variaveis/variaveis';
import { CpfFormat } from '../../components/services/CpfFormat';
import { cpfValidate } from '../../components/services/CpfValidate';
import { TermsAndServicesCheckBox } from './TermsAndServices/TermsAndServices';
import InputModel, { Field } from '../../components/Input/InputModel/InputModel';
import { usePasswordValidation } from '../../components/hooks/Password/usePasswordValidation';
import { useForm, Controller } from 'react-hook-form';
import TextModel from '../../components/Text/text';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import dayjs from 'dayjs';
import 'dayjs/locale/pt';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DateFormat } from '../../components/services/DateFormat';
import { RegnumCheckBox } from './RegnumCheckbox/RegnumCheckbox';
dayjs.extend(customParseFormat);

interface SignupFormProps {
    emailLogin: string;
}

const SignupForm: React.FC<SignupFormProps> = ({ emailLogin }) => {
    const { control, handleSubmit, formState: { errors } } = useForm();
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const [fullName, setFullName] = useState('');
    const [taxId, setTaxId] = useState('');
    const [birthDate, setBirthDate] = useState(null);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState('');
    const [taxIdError, setTaxIdError] = useState('');
    const [currentStep, setCurrentStep] = useState(1);
    const [isRegnum, setIsRegnum] = useState(false);

    const navigate = useNavigate();
    const { customErrors, handlePasswordChange, handleConfirmPasswordChange } = usePasswordValidation();

    useEffect(() => {
        setEmail(emailLogin);
    }, [emailLogin]);

    const handleRegnum = (e: React.ChangeEvent<HTMLInputElement>) => {
        const value = e.target.checked;
        setTaxIdError('');

        if (value) {
            setIsRegnum(true);
        } else {
            setIsRegnum(false);
        }
    };

    const handlePasswordInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setPassword(e.target.value);
        handlePasswordChange(e);
    };

    const handleConfirmPasswordInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setConfirmPassword(e.target.value);
        handleConfirmPasswordChange(e);
    };

    const fields: Field[] = [
        { label: "Password", type: "password", placeholder: "*********", name: "password", onChange: handlePasswordInputChange },
        { label: "Confirm Password", type: "password", placeholder: "*********", name: "confirmPassword", onChange: handleConfirmPasswordInputChange },
    ];

    const submitSignUp = async () => {
        setLoading(true);
        setError('');

        const requestData = {
            password: password,
            confirmPassword: confirmPassword,
            email: email
        };

        try {
            const response = await fetch(`${ENDPOINT}/create`, {
                method: 'POST',
                credentials: 'include',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify(requestData),
            });

            if (response.ok) {
                localStorage.setItem('email', email);
                navigate('/validate-email');
                return;
            }

            const data = await response.json();

            if (!response.ok) {
                setError(data.error);
                throw new Error(data.error || 'Error to submit signup');
            }
        } catch (error) {
            if (error instanceof Error) {
                setError(error.message);
            } else {
                setError('Ocorreu um erro desconhecido');
            }
        } finally {
            setLoading(false);
        }
    };

    const handleSignup = async (e: FormEvent) => {
        e.preventDefault();
        switch (currentStep) {
            case 1:
                await submitSignUp();
                break;
            default:
                console.log('Passo inválido');
        }
    };

    const handleTaxIdChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setTaxId(e.target.value);
        if (!isRegnum) {
            if (!cpfValidate(e.target.value)) {
                setTaxIdError('Please enter a valid TaxId');
            } else {
                setTaxIdError('');
            }
        }
    };

    function Step1Valid() {
      return  !(customErrors.length > 0 && currentStep === 1) 
        
    }

    function isDisabled() {

  
            return  !Step1Valid() || loading;
        
        

           
    }

    return (
        <div className='flex justify-center items-center h-screen'>
            <form onSubmit={handleSignup} className="w-[382px] max-h-[650px] bg-white rounded-lg shadow-lg p-6">
                <LogoGreen />
                <span className="font-inter font-bold text-2xl tracking-tight text-gray-800 block mb-6 mt-10">Cadastro</span>
                {currentStep === 1 && (
                    
                    <div className='text-left'>
                           <div className="flex items-center w-full h-[52px] gap-4 mb-4 bg-gray-100">
                            <FontAwesomeIcon icon={faEnvelope} className="h-4 w-4 ml-4 text-gray-500" />
                            <input type="email" className="form-input w-full py-2 rounded-md bg-gray-100 border-transparent ring-0 focus:border-transparent focus:ring-0" placeholder="examplo@domain.com" value={email} onChange={(e) => setEmail(e.target.value)} />
                        </div>
                        {fields.map((item, index) => (
                            <div key={index} className="mb-4">
                                <InputModel {...item} />
                            </div>
                        ))}
                        <ul className="space-y-2">
                            {customErrors.map((error, index) => (
                                <li key={index} className="flex items-center text-red-600 text-sm">
                                    <FontAwesomeIcon icon={faTimes} className="h-4 w-4 mr-2" />
                                    <span>{error}</span>
                                </li>
                            ))}
                        </ul>
                    </div>
                )}
     
        
                {currentStep === 2 && (
                    <>
                        <div className="flex items-center w-full h-[52px] gap-4 mb-4 bg-gray-100">
                            <FontAwesomeIcon icon={faFile} className="h-4 w-4 ml-4 text-gray-500" />
                            <input type="text" className="form-input w-full py-2 rounded-md bg-gray-100 border-transparent ring-0 focus:border-transparent focus:ring-0" placeholder="CPF" value={taxId} onChange={handleTaxIdChange} onInput={!isRegnum ? CpfFormat : undefined} />
                        </div>
                        <div className="flex items-center w-full h-[52px] gap-4 mb-4 bg-gray-100">
                            <input type="text" className="form-input w-full py-2 rounded-md bg-gray-100 border-transparent ring-0 focus:border-transparent focus:ring-0 ml-5" placeholder="Nome completo" onChange={(e) => setFullName(e.target.value)} />
                        </div>

                        <div className="flex flex-col my-3">
                            <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="pt">
                                <Controller
                                    control={control}
                                    name='BirthDate'
                                    rules={{ required: true }}
                                    render={({ field }) => {
                                        return (
                                            <DatePicker
                                                label='Birth Date'
                                                value={field.value ? dayjs(field.value, "DD/MM/YYYY") : null}
                                                onChange={(date:any) => {
                                                    const formattedDate = date ? date.format("DD/MM/YYYY") : null;
                                                    field.onChange(formattedDate);
                                                    setBirthDate(formattedDate as any);
                                                }}
                                            />
                                        );
                                    }}
                                />
                            </LocalizationProvider>

                            {errors.birthDate && <TextModel addons={`text-sm`} color='text-red-600' content={errors.birthDate?.message} />}
                        </div>
                        <TermsAndServicesCheckBox />
                    </>
                )}
                   <Button type="submit" disabled={isDisabled()} className={`w-full flex justify-center items-center px-5 py-1 bg-[#0A1F27] rounded-lg text-white mt-10 ${isDisabled() ? 'opacity-50 cursor-not-allowed' : ''}`}>
                    {loading ? 'Loading...' : 'Próximo'}
                </Button>
                {error && <div className="text-red-500 mt-2">{error}</div>}
                {taxIdError && <div className="text-red-500 mt-2">{taxIdError}</div>}
            </form>
        </div>
    );
}

export const validatePassword = (password: string, confirmPassword: string): string[] => {
    const errors: string[] = [];
    if (password === '') {
        errors.push("As senhas não podem estar vazias!");
    }
    if (!/[!@#%&*]/.test(password)) {
        errors.push("Utilize pelo menos um caractere especial (@!#%&*)");
    }
    if (!/[A-Z]/.test(password)) {
        errors.push("Use at least one uppercase letter");
    }
    if (password.includes("name") || password.includes("email")) {
        errors.push("Não use seu nome ou e-mail");
    }
    if (password.length < 8) {
        errors.push("Sua senha deve conter pelo menos 8 caracteres");
    }
    if (password !== confirmPassword) {
        errors.push("As senhas não são iguais!");
    }
    return errors;
};

export default SignupForm;
